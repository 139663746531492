<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-form class="container">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label>Név</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>E-mail</label>
              <validation-provider
                #default="{ errors }"
                rules="required|email"
              >
                <b-form-input
                  v-model="formData.email"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Jelszó</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules=""
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="formData.password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Jelszó megerősítése</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules=""
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password-confirmation"
                    v-model="formData.password_confirmation"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password-confirmation"
                    placeholder="············"
                    autocomplete="off"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Mentés
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
      },

      required,
      email,
    }
  },
  methods: {
    ...mapActions(['updateUser', 'getUser']),
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateUser(this.formData).then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeres mentés',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.replace({
              name: 'users-index',
            })
          })
        }
      })
    },
    initUser() {
      this.loading = true
      this.getUser(this.$route.params.id).then(res => {
        this.formData.id = res.id
        this.formData.name = res.name
        this.formData.email = res.email
        this.loading = false
      })
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.initUser()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
